<template>
  <section class="section">
    <div class="container">
      <div class="login-panel">
        <div class="heading text-center">
          <h1>Start hosting<br class="d-none d-lg-block"> online video meetings for free</h1>
          <!-- <p>Simply add your details below to get started.</p> -->
        </div>
        <form
          class="form-horizontal mt-2"
          @submit.prevent="login"
        >
          <div
            class="form-group"
            :class="{ 'form-error': errors.email }"
          >
            <label class="sr-only">Email address</label>
            <input
              id="email"
              ref="email"
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Email address"
            >
            <div
              v-for="(error, index) in errors.email"
              :key="index"
              class="form-error-message"
            >
              {{ error }}
            </div>
          </div>
          <div
            class="form-group"
            :class="{ 'form-error': errors.password }"
          >
            <label class="sr-only">Password</label>
            <input
              id="pass"
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
            >
            <div
              v-for="(error, index) in errors.password"
              :key="index"
              class="form-error-message"
            >
              {{ error }}
            </div>
          </div>
          <router-link
            class="float-right mb-1"
            :to="{name: 'forgot-password'}"
          >
            Forgot your Password?
          </router-link>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-lg btn-block btn-primary"
            >
              Log In
            </button>
            <div
              class="btn btn-lg btn-block btn-primary no-margin"
              @click="handleSSO()"
            >
              Log In via SSO
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import {authStore} from '@/store/__STORE_auth';
export default {
  data() {
    return {
      email: '',
      password: '',
      errors: [],
    };
  },
  mounted() {
    window.setTimeout(() => {
      this.$refs.email.focus();
    }, 400);
  },
  methods: {
    async handleSSO() {
      await this.$auth.loginWithRedirect({appState: {targetUrl: {name: 'sso-confirm'}}});
    },
    login() {
      const email = this.email;
      const password = this.password;
      authStore.login({email, password})
          .then(() => {
            this.$toast.success('Login successful!');
            this.$router.push('/dashboard');
            if (this.deviceToken) {
              const payload = {
                device_token: this.deviceToken,
                device_type: 'web',
              };
              localStorage.setItem('storeDeviceToken', this.deviceToken);
              authStore.storeDeviceToken(payload);
            }
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
            if (err.response.status === 422) {
              if (err.response.data.errors) {
                this.errors = err.response.data.errors;
              } else {
                this.errors = [];
              }
            } else if (err.response.status === 403) {
              this.$router.push({
                name: 'need-to-verify',
                params: {
                  email: this.email,
                },
              });
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-margin {
  margin: 10px 0 0 0 !important;
}
</style>
